import type { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import LogRocket from "logrocket";
import { EyeIcon, EyeOffIcon, LoaderCircle } from "lucide-preact";
import { z } from "zod";

import { evaluateUserCredentials } from "@/services/auth";
import { showError } from "@/services/toasts";
import { authStore } from "@/stores/authstore";
import { withQueryClientProvider } from "@/utils/queryClient";
import { getQueryParam } from "@/utils/url";

const signInFormSchema = z.object({
  email: z.string().email("Please enter a valid email."),
  password: z.string().min(8, "Password must be at least 8 characters."),
});

const signUpFormSchema = signInFormSchema
  .extend({
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: "Passwords do not match",
  });

interface Props {
  isSignUp?: boolean;
}

export const SignInWithEmailAndPasswordForm: FunctionComponent<Props> = withQueryClientProvider(({ isSignUp }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof signUpFormSchema>>({
    defaultValues: {
      email: getQueryParam("email") ?? "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(isSignUp ? signUpFormSchema : signInFormSchema),
  });

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: async (data: z.infer<typeof signInFormSchema>) => {
      authStore.setKey("loading", true);
      try {
        const result = await createUserWithEmailAndPassword(getAuth(), data.email, data.password);
        await evaluateUserCredentials(result);
      } catch (e) {
        if (e instanceof FirebaseError && e.code === "auth/email-already-in-use") {
          const result = await signInWithEmailAndPassword(getAuth(), data.email, data.password);
          await evaluateUserCredentials(result);
        } else if (e instanceof FirebaseError) {
          showError(e.message);
        } else {
          LogRocket.captureException(e as Error);
        }
      } finally {
        authStore.setKey("loading", false);
      }
    },
  });

  useEffect(() => {
    const messages = Object.values(errors)
      .concat(errors.root ?? [])
      .map(it => it.message)
      .filter(it => !!it)
      .join(", ");
    if (messages.length === 0) return;
    showError(messages);
  }, [errors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} class="flex max-w-sm flex-col gap-3">
      <h3 class="text-slate-600 text-sm">
        {isSignUp ? "Create your account using a strong password" : "Sign in with your existing account"}
      </h3>
      <div class="relative w-full mt-2">
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <input
                type="email"
                name={field.name}
                required
                onChange={field.onChange}
                disabled={isPending}
                value={field.value}
                class={clsx(
                  "peer p-4 block w-full  rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600 focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2",
                  { "border-red-500": !!error, "border-gray-200": !error },
                )}
                placeholder="you@email.com"
              />
            );
          }}
        />

        <label
          for="hs-floating-input-email"
          class="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] peer-disabled:opacity-50 peer-disabled:pointer-events-none
              peer-focus:scale-90
              peer-focus:translate-x-0.5
              peer-focus:-translate-y-1.5
              peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
              peer-[:not(:placeholder-shown)]:scale-90
              peer-[:not(:placeholder-shown)]:translate-x-0.5
              peer-[:not(:placeholder-shown)]:-translate-y-1.5
              peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">
          Email
        </label>
      </div>
      <div class="relative">
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <input
                  type={showPassword ? "text" : "password"}
                  name={field.name}
                  required
                  onChange={field.onChange}
                  disabled={isPending}
                  value={field.value}
                  class={clsx(
                    "peer p-4 block w-full  rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600 focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2",
                    { "border-red-500": !!error, "border-gray-200": !error },
                  )}
                />
                {field.value.length > 0 && (
                  <button
                    onClick={() => setShowPassword(it => !it)}
                    type="button"
                    className="absolute right-3 top-4 text-gray-500 dark:text-neutral-500">
                    {showPassword ? <EyeIcon size={20} /> : <EyeOffIcon size={20} />}
                  </button>
                )}
              </>
            );
          }}
        />
        <label
          for="hs-floating-input-passowrd"
          class="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] peer-disabled:opacity-50 peer-disabled:pointer-events-none
              peer-focus:scale-90
              peer-focus:translate-x-0.5
              peer-focus:-translate-y-1.5
              peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
              peer-[:not(:placeholder-shown)]:scale-90
              peer-[:not(:placeholder-shown)]:translate-x-0.5
              peer-[:not(:placeholder-shown)]:-translate-y-1.5
              peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">
          Password
        </label>
      </div>
      {isSignUp && (
        <div class="relative">
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name={field.name}
                    required
                    onChange={field.onChange}
                    disabled={isPending}
                    value={field.value}
                    class={clsx(
                      "peer p-4 block w-full  rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600 focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2",
                      { "border-red-500": !!error, "border-gray-200": !error },
                    )}
                  />
                  {field.value.length > 0 && (
                    <button
                      onClick={() => setShowConfirmPassword(it => !it)}
                      type="button"
                      className="absolute right-3 top-4 text-gray-500 dark:text-neutral-500">
                      {showConfirmPassword ? <EyeIcon size={20} /> : <EyeOffIcon size={20} />}
                    </button>
                  )}
                </>
              );
            }}
          />
          <label
            for="hs-floating-input-passowrd"
            class="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] peer-disabled:opacity-50 peer-disabled:pointer-events-none
              peer-focus:scale-90
              peer-focus:translate-x-0.5
              peer-focus:-translate-y-1.5
              peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
              peer-[:not(:placeholder-shown)]:scale-90
              peer-[:not(:placeholder-shown)]:translate-x-0.5
              peer-[:not(:placeholder-shown)]:-translate-y-1.5
              peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">
            Confirm Password
          </label>
        </div>
      )}
      <button
        type="submit"
        disabled={isPending}
        class="py-3 px-4 inline-flex items-center justify-center gap-x-2 text-sm font-semibold rounded-lg border bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:focus:bg-white/20 dark:focus:text-white">
        {isPending && <LoaderCircle size={20} className="animate-spin" />}
        <span>{isSignUp ? "Sign Up" : "Sign In"}</span>
      </button>
      {isSignUp ? (
        <p className="text-xs text-right text-slate-400">
          Already have an account?{" "}
          <a className="underline hover:text-slate-700" href="/">
            Sign in instead
          </a>
        </p>
      ) : (
        <div className="flex justify-between">
          <p className="text-xs text-right text-slate-400">
            Got an invite?{" "}
            <a className="underline hover:text-slate-700" href="/sign-up">
              Sign up
            </a>
          </p>
          <p className="text-xs text-right text-slate-400">
            Can't remember?{" "}
            <a className="underline hover:text-slate-700" href="/reset-password">
              Reset password
            </a>
          </p>
        </div>
      )}
    </form>
  );
});
